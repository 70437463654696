import React, { lazy, Suspense } from 'react';
import { Route, Routes } from 'react-router-dom';
import Loader from '../Components/Loader';
import SearchPage from '../Pages/Search';
import Cart from '../Pages/Cart';

const LazyLoad = () => {

  const Home = lazy(() => import("../Pages/Home"));
  const Category = lazy(() => import("../Pages/Category"));
  const Singleproducts = lazy(() => import("../Pages/Singleproducts"));
  const Contact = lazy(() => import("../Pages/Contact"));
  const About = lazy(() => import("../Pages/About"));
  const Cart = lazy(() => import("../Pages/Cart"));
  const Orders = lazy(() => import("../Pages/Orders"));
  const Transaction = lazy(() => import("../Pages/Transaction"));

  return (
    <>
    <Suspense fallback={<Loader />}>
        <Routes>  
                <Route path="/" element={<Home />} />
                {/* <Route path="/category" element={<Category />} /> */}
                <Route path="category/:categoryName" element={<Category />} />
                {/* <Route path="/single-product" element={<Singleproducts />} /> */}
                <Route path="product/:productHandle" element={<Singleproducts />} />
                <Route path="search/:word" element={<SearchPage />} />

                <Route path="contact" element={<Contact />} />
                <Route path="about" element={<About />} />

                <Route path="cart" element={<Cart />} />
                <Route path="orders/:orderId" element={<Orders />} />
                <Route path="transaction/:transactionStatus" element={<Transaction />} />
        </Routes>
    </Suspense> 
    </>
   
  )
}

export default LazyLoad