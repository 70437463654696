import React, { createContext, useState, useContext, useEffect } from 'react';
import { API_PATH, APP_NAME } from '../../constants';

const CartContext = createContext();

export const CartProvider = ({ children }) => {
  const [cartCount, setCartCount] = useState(0);

  useEffect(() => {
    fetchCartCount();
  }, []);

  const fetchCartCount = async () => {
    try {
      // Check if the user is logged in
      const user = JSON.parse(localStorage.getItem('user'));
      if (user && user.id) {
        // User is logged in, fetch cart count from API
        const postData = {
          tbl_name: "cart",
          user_id: user.id,
        };

        const requestOptions = {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify(postData)
        };

        const response = await fetch(`${API_PATH}/get.php`, requestOptions);
        const data = await response.json();

        // Ensure data is an array and set cart count
        if (Array.isArray(data)) {
          setCartCount(data.length);
        } else {
          console.error('Unexpected data format:', data);
          setCartCount(0);
        }

      } else {
        // User is not logged in, retrieve cart count from localStorage
        const cartItems = JSON.parse(localStorage.getItem(`${APP_NAME}_cart`)) || [];
        setCartCount(cartItems.length);
      }
    } catch (error) {
      console.error('Error fetching cart count:', error);
      setCartCount(0);
    }
  };

  const updateCartCount = (newCount) => {
    setCartCount(newCount);
  };

  return (
    <CartContext.Provider value={{ cartCount, updateCartCount }}>
      {children}
    </CartContext.Provider>
  );
};

export const useCart = () => useContext(CartContext);
