"use client";
import React, { useState, useEffect } from "react";
import { useParams } from 'react-router-dom';
import { API_PATH } from '../constants';
import ProductCard from '../Components/Shared/ProductCard';
import SEOComponent from "../Components/Shared/SEOComponent";

const SearchPage = () => {
  const [searchResults, setSearchResults] = useState(null);
  const { word } = useParams();
//   let searchHeading = `Search Results for ${word}`;
  const [searchHeading, setSearchHeading] = useState(null);


    // Call search API with the search term
    // Replace 'https://example.com/api/search' with your actual search API endpoint
    // Fetch handpicked products data from your API

    useEffect(() => {
        let extraCondition = '';
        if (word !== 'all') {
            extraCondition = ` AND title LIKE '%${word}%'`;
        }
        const postData = {
            tbl_name: "products",
            extra_condition: extraCondition
            // Add other data properties as needed
        };
    
        // Define the fetch options
        const requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json' // Specify the content type as JSON
            },
            body: JSON.stringify(postData) // Convert the data to JSON format
        };
    
        // Replace the placeholder URL with your actual API endpoint
        fetch(`${API_PATH}/get.php`, requestOptions)
            .then(response => {
                if (!response.ok) {
                    throw new Error('Failed to fetch handpicked products');
                }
                return response.json();
            })
            .then(data => {
                // Update the state with the fetched handpicked products data
                setSearchResults(data);
                if (word == 'all'){
                    setSearchHeading("Explore our Products");
                } else {
                    if (data.length <= 0) {
                        setSearchHeading(`No search results for "${word}"`);
                    } else {
                        setSearchHeading(`Search Results for "${word}"`);
                    }
                }
            })
            .catch(error => {
                console.error('Error fetching handpicked products:', error);
            });
    }, [word]);

    return (
        <>
        <SEOComponent title={word == 'all' ? 'All Products' : `Search results for ${word}`} description={`Search results for ${word} from Vyra Herbals`} />
        <section className="section deals-part">
            <div className="container">
                <div className="row">
                    <div className="col-lg-12">
                        <div className="section-heading search-head">
                            <h2>{searchHeading}</h2>   
                        </div>
                    </div>
                </div>
                
                <div className="row row-cols-2 row-cols-md-3 row-cols-lg-4 row-cols-xl-4">
        
                    {searchResults ?
                        searchResults.map(product => (
                            <ProductCard key={product.id} product={product}/>
                        )):
                        <>
                            {
                                [1,2,3,4].map(k => (
                                <div className='col-md-3 p-3' key={k}>
                                    <div style={{padding: '8px', border: "1px solid black", borderRadius: "8px"}}>
                                        <div style={{height: "240px", backgroundColor: 'lightgray'}}></div>
                                        <div style={{marginLeft: '8px', padding: "8px"}}>
                                            <h3>Loading..</h3>
                                            <p>....</p>
                                            <p>.... ....</p>
                                        </div>
                                    </div>
                                </div>
                            ))
                            
                            }
                        </>
                    }
                    
                </div>
                {/* <div className="row">
                    <div className="col-lg-12">
                        <div className="section-btn-25"><a href="/" className="btn btn-inline"><i className="fas fa-eye"></i><span>view all Products</span></a></div>
                    </div>
                </div> */}
            </div>
        </section>
        </>
    )

};

export default SearchPage;
