
export const DOMAIN_NAME = 'https://store.bridzo.com';

// export const DOMAIN_NAME = 'http://localhost:3000';

// export const API_PATH = 'http://localhost/spro/vyra-api';

export const API_PATH = 'https://store.bridzo.com/api';

export const APP_NAME = 'VyraStore';
