import { createContext, useEffect, useState } from 'react';
import Header from './Components/Common/Header';
import Footer from './Components/Common/Footer';
import Home from './Pages/Home';
import LazyLoad from './routes/LazyLoad';


// import reactLogo from './assets/react.svg'
// import viteLogo from '/vite.svg'
// import './App.css'

function App() {
  const [count, setCount] = useState(0)

  return (
    <>
  
      <Header />
      {/* <Home /> */}
      
      <LazyLoad/>
      
      <Footer />
      
    </>
  )
}

export default App
