import { Link } from "react-router-dom";

const ProductCard = ({product}) => {
    return <div className="col">
        <div className="product-card">
            <div className="product-media">
            <button className="product-wish wish"><i className="fas fa-heart"></i></button>
                <a className="product-image" href={`/product/${product.handle}`}><img src={product.image_url} alt="product" /></a>
                <div className="product-widget">
                    <a title="Product Compare" href="/" className="fas fa-random"></a>
                    <a title="Product Video" href="#" className="venobox fas fa-play" data-autoplay="true" data-vbtype="video"></a>
                    <a title="Product View" href="#" className="fas fa-eye" data-bs-toggle="modal" data-bs-target="#product-view"></a>
                </div>
            </div>
            <div className="product-content">
            {/* <h6 className="product-name"><Link to={`/product/${product.handle}`}> {product.title} </Link></h6> */}
            <h6 className="product-name"><a href={`/product/${product.handle}`}> {product.title} </a></h6>
                <div className="product-rating"><i className="active icofont-star"></i><i className="active icofont-star"></i><i className="active icofont-star"></i><i className="active icofont-star"></i><i className="icofont-star"></i><a href="/single-product">(3)</a></div>
                <h6 className="product-price"><span>₹{product.price}<small></small></span><del>₹{product.regular_price}</del></h6><button className="product-add" title="Add to Cart"><i className="fas fa-shopping-basket"></i><span>add</span></button>
                <div className="product-action"><button className="action-minus" title="Quantity Minus"><i className="icofont-minus"></i></button><input className="action-input" title="Quantity Number" type="text" name="quantity" value="1" /><button className="action-plus" title="Quantity Plus"><i className="icofont-plus"></i></button></div>
            </div>
        </div>
    </div>
}
export default ProductCard;